import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import config from "../../config" // Asegúrate de que esta ruta sea correcta
import Seo from "../../components/seo"
import ContentPageLayout from "../../components/ContentPageLayout"
import ContentSkeleton from "../../components/ContentSkeleton"
import BackButton from "../../components/buttons/BackButton"
import { FaSearch, FaTimes } from "react-icons/fa"
import CategoryBlock from "../../components/content/categoryBlock"
import { firebaseInit } from "../../config.js"
import firebase from "firebase"

const Coach = ({ params }) => {
  const profileUri = config.serverUri
  const [blocks, setBlocks] = useState([])
  const [coachBanner, setCoachBanner] = useState(null)
  const [searchVisible, setSearchVisible] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [filteredBlocks, setFilteredBlocks] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [noResults, setNoResults] = useState(false)
  const [imageHeight, setImageHeight] = useState("auto")
  const [userHasSuscription, setUserHasSuscription] = useState(null)
  const [coachData, setCoachData] = useState(null) // Para guardar nombre y bio

  const coachId = params.id

  useEffect(() => {
    const fetchCoachData = async () => {
      try {
        setIsLoading(true)

        // Fetch coach details
        const coachResponse = await fetch(
          `${config.serverUri}/coaches/${coachId}`
        )
        const coachDetails = await coachResponse.json()
        setCoachData(coachDetails) // Guarda la información del coach

        // Fetch coach blocks
        const blocksResponse = await fetch(
          `${config.serverUri}/coaches/content-coach/${coachId}`
        )
        const blocksData = await blocksResponse.json()
        setBlocks(blocksData.blocks || [])
        setFilteredBlocks(blocksData.blocks || [])

        // Set image height based on banner dimensions
        if (coachDetails.bannerDesktop && coachDetails.bannerDesktop.height) {
          const aspectRatio =
            coachDetails.bannerDesktop.height / coachDetails.bannerDesktop.width
          setImageHeight(window.innerWidth * aspectRatio)
        }
      } catch (error) {
        console.error("Error fetching coach data:", error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchCoachData()
  }, [coachId])

  useEffect(() => {
    firebaseInit(firebase)
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        try {
          const response = await fetch(
            `${profileUri}/users/?email=${user.email}`
          )
          if (response.ok) {
            const dataUser = await response.json()
            setUserHasSuscription(dataUser[0]?.suscription || false)
          } else {
            console.error("Error al obtener la suscripción del usuario")
            setUserHasSuscription(false)
          }
        } catch (error) {
          console.error("Error al validar la suscripción:", error)
          setUserHasSuscription(false)
        }
      } else {
        console.log("Usuario no autenticado")
        setUserHasSuscription(false)
      }
    })
  }, [])

  const toggleSearch = () => {
    setSearchVisible(!searchVisible)
    setSearchText("")
    setNoResults(false)
    setFilteredBlocks(blocks) // Reset filtered blocks
  }

  const handleSearch = () => {
    if (!searchText.trim()) {
      setFilteredBlocks(blocks)
      setNoResults(false)
      return
    }

    const lowerCaseSearchText = searchText.toLowerCase()

    const results = blocks.filter(block =>
      block.cards.some(card =>
        card.titulo.toLowerCase().includes(lowerCaseSearchText)
      )
    )

    setFilteredBlocks(results)
    setNoResults(results.length === 0)
  }

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      handleSearch()
    }
  }

  const getImageUrl = url =>
    url?.startsWith("http") ? url : `${config.serverUri}${url}`

  return (
    <ContentPageLayout>
      <Seo title="Coach" />
      {isLoading ? (
        <ContentSkeleton />
      ) : (
        <div>
          {/* Search Bar */}
          <div className="flex p-4">
            <BackButton fontSize={30} route="/" />
            <div className="ml-auto flex items-center justify-end">
              {!searchVisible ? (
                <FaSearch
                  fontSize={18}
                  className="cursor-pointer"
                  onClick={() => setSearchVisible(true)}
                />
              ) : (
                <>
                  <input
                    type="text"
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Buscar..."
                    className="bg-transparent border-b focus:outline-none pr-4"
                  />
                  <FaTimes
                    fontSize={18}
                    className="cursor-pointer ml-2"
                    onClick={toggleSearch}
                  />
                </>
              )}
            </div>
          </div>

          {/* Banner */}
          {coachData && coachData.bannerDesktop && (
            <img
              src={getImageUrl(coachData.bannerDesktop.url)}
              style={{
                width: "auto",
                height: `${imageHeight}px`,
                objectFit: "cover",
              }}
            />
          )}

          {/* Blocks */}
          <main className="p-8 mb-32">
            {/* Coach Info */}
            {coachData && (
              <div className="">
                <h1 className="text-2xl font-bold mb-1">{coachData.nombre}</h1>
                <p className="text-gray-600">{coachData.bio}</p>
              </div>
            )}

            {!blocks.length ? (
              <p>No hay contenido para mostrar.</p>
            ) : noResults ? (
              <p>No hay elementos que coincidan con la búsqueda.</p>
            ) : (
              filteredBlocks.map(block => (
                <CategoryBlock
                  key={block._id}
                  block={block}
                  hideOverlayAndImage={true}
                  idCategory={coachId}
                  categoryPath={"coaches"}
                  userHasSuscription={userHasSuscription}
                />
              ))
            )}
          </main>
        </div>
      )}
    </ContentPageLayout>
  )
}

export default Coach
